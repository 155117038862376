import { computed } from "@vue/composition-api";
import { useFind, useGet } from "feathers-vuex";
import Vue from "vue";

import {
  findAllContractorOrganizations,
  findAllDesignOrganizations,
  findAllOrganizations
} from "@/store/queries/organizations";

const { Organization } = Vue.$FeathersVuex.api;
import themeConfig from "@/themeConfig";

export function useOrganizationList() {
  //const organizations = ref([]);
  const organizationsParams = computed(findAllOrganizations);
  // Organization.find(organizationsParams.value).then((res) => {
  //   organizations.value = res.data;
  // });
  const organizationsLocalParams = computed(() => {
    return {
      query: {
        status: "Active",
        $sort: {
          short_name: 1
        }
      }
    };
  });
  const { items: organizations } = useFind({
    model: Organization,
    params: organizationsLocalParams.value,
    fetchParams: organizationsParams.value,
    qid: "organizations"
  });

  return {
    organizations
  };
}

export function resolveOrganizationLogo(organizationId) {
  return `${themeConfig.app.baseAvatarUrl}/avatars/orgs/${organizationId}.png`;
}

export function resolveAltLogo() {
  return `${themeConfig.app.baseAvatarUrl}/avatars/orgs/null.png`;
}

export function useContractorOrganizationList() {
  const contractorOrganizationsParams = computed(
    findAllContractorOrganizations
  );

  const { items: contractorOrganizations, isPending } = useFind({
    model: Organization,
    params: contractorOrganizationsParams.value,
    qid: "contractorOrganizations"
  });

  return {
    contractorOrganizations,
    loadingContractorOrganizations: isPending
  };
}

export function useDesignOrganizationList() {
  const designOrganizationsParams = computed(findAllDesignOrganizations);
  const designOrganizationsLocalParams = computed(() => {
    return {
      query: {
        $sort: {
          short_name: 1
        }
      }
    };
  });

  const {
    items: designFirms,
    isPending,
    qid,
    latestQuery
  } = useFind({
    model: Organization,
    params: designOrganizationsLocalParams.value,
    fetchParams: designOrganizationsParams.value
  });

  return {
    designFirms,
    loadingDesignFirms: isPending,
    qid,
    latestQuery
  };
}

export function useGetOrganizationById(organizationId) {
  console.log(organizationId);

  const { item: organizationRecord, hasLoaded: organizationRecordHasLoaded } =
    useGet({
      model: Organization,
      id: organizationId
    });

  return {
    organizationRecord,
    organizationRecordHasLoaded
  };
}
