/**
 * @file Project Types
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the ProjectTypes service
 */

/**
 * Find All Project Types
 * @description Returns all project types.
 *
 *
 * @returns {object}
 */

function findAllProjectTypes() {
  return {
    query: {
      $select: ["id", "display_type", "display_order"],
      is_active: true,
      $sort: {
        display_order: 1
      }
    }
  };
}
exports.findAllProjectTypes = findAllProjectTypes;
