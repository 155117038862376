import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { findAllProjectTypes } from "@/store/queries/project-types";

const { ProjectType } = Vue.$FeathersVuex.api;

export function useProjectTypeList() {
  const projectTypesParams = computed(findAllProjectTypes);

  const { items: projectTypes, isPending } = useFind({
    model: ProjectType,
    params: projectTypesParams.value,
    qid: "projectTypeList"
  });

  return {
    projectTypes,
    isPending
  };
}
