/**
 * @file Fiscal Years
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the Fiscal Years service
 */

/**
 * Find All Fiscal Years
 * @description Returns all fiscal years.
 *
 *
 * @returns {object}
 */

function findAllFiscalYears() {
  return {
    query: {
      $select: ["id", "display_year", "display_order"],
      is_active: true,
      $sort: {
        display_order: -1
      }
    }
  };
}
exports.findAllFiscalYears = findAllFiscalYears;
