/**
 * @file Contacts
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the Contacts service
 */

/**
 * Find All Contacts
 * @description Returns all contacts.
 *
 *
 * @returns {object}
 */

function findAllContacts() {
  return {
    query: {
      $sort: {
        last_name: 1
      }
    }
  };
}
exports.findAllContacts = findAllContacts;

/**
 * Find All Designer Contacts
 * @description Returns all designers.
 *
 *
 * @returns {object}
 */

function findAllDesignerContacts() {
  return {
    query: {
      $select: [
        "fulcrum_id",
        "first_name",
        "last_name",
        "org_name",
        "contact_position",
        "tpm_designer"
      ],
      tpm_designer: "Yes",
      $sort: {
        org_name: 1,
        last_name: 1
      }
    }
  };
}
exports.findAllDesignerContacts = findAllDesignerContacts;

/**
 * Find All Lead Inspector Contacts Query
 * @description Returns all contacts having the position of Lead Inspector.
 *
 * @returns {array} The query.
 *
 */
function findAllLeadInspectorContacts() {
  return {
    query: {
      $select: [
        "fulcrum_id",
        "first_name",
        "last_name",
        "org_name",
        "tpm_const_inspector"
      ],
      tpm_const_inspector: "Yes",
      $sort: {
        org_name: 1,
        last_name: 1
      }
    }
  };
}
exports.findAllLeadInspectorContacts = findAllLeadInspectorContacts;
