import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import {
  findAllDesignerContacts,
  findAllLeadInspectorContacts
} from "@/store/queries/contacts";

const { Contact } = Vue.$FeathersVuex.api;

export function useDesignerContactsList() {
  const designerContactsParams = computed(findAllDesignerContacts);

  const { items: designerContacts, isPending } = useFind({
    model: Contact,
    params: designerContactsParams.value,
    qid: "designerContactList"
  });

  /**
   * We need to get the following structure:
   *  designerContacts = [
   *    { header: 'Org 1'},
   *    { first_name: 'John', last_name: 'Smith', org_name: 'Org 1', etc...}
   *    { header: 'Org 2'},
   *    { first_name: 'Jack', last_name: 'Harkness', org_name: 'Org 2', etc...}
   *  ];
   *
   */
  const groupedDesignerContacts = computed(() => {
    return useGroupedContactsByOrganization(designerContacts);
  });

  function useGroupedContactsByOrganization(contacts) {
    // Create an intermediate object to hold categories.
    const groups = {};

    // Create array for each group to main subgroup list.
    contacts.value.forEach((x) => {
      // create empty object if it doesn't exists.
      groups[x.org_name] = groups[x.org_name] || { name: x.org_name, list: [] };

      groups[x.org_name].list.push(x);
    });

    // The flattened list of items that holds items as well as unique headers
    const flattened = [];

    // Iterate over all the unique categories and
    // then flatten into a list that v-select needs.
    Object.keys(groups).forEach((categoryId) => {
      const category = groups[categoryId];
      const categoryName = category.name;

      // Create a group
      flattened.push({ header: categoryName });

      // Add all the items followed by category header
      flattened.push(...category.list);
    });

    return flattened;
  }

  return {
    designerContacts,
    isPending,
    groupedDesignerContacts
  };
}

export function useLeadInspectorContactsList() {
  const leadInspectorContactsParams = computed(findAllLeadInspectorContacts);

  const { items: leadInspectorContacts, isPending } = useFind({
    model: Contact,
    params: leadInspectorContactsParams.value,
    qid: "leadInspectorContactList"
  });

  /**
   * We need to get the following structure:
   *  leadInspectorContacts = [
   *    { header: 'Org 1'},
   *    { first_name: 'John', last_name: 'Smith', org_name: 'Org 1', etc...}
   *    { header: 'Org 2'},
   *    { first_name: 'Jack', last_name: 'Harkness', org_name: 'Org 2', etc...}
   *  ];
   *
   */
  const groupedLeadInspectorContacts = computed(() => {
    return useGroupedContactsByOrganization(leadInspectorContacts);
  });

  function useGroupedContactsByOrganization(contacts) {
    // Create an intermediate object to hold categories.
    const groups = {};

    // Create array for each group to main subgroup list.
    contacts.value.forEach((x) => {
      // create empty object if it doesn't exists.
      groups[x.org_name] = groups[x.org_name] || { name: x.org_name, list: [] };

      groups[x.org_name].list.push(x);
    });

    // The flattened list of items that holds items as well as unique headers
    const flattened = [];

    // Iterate over all the unique categories and
    // then flatten into a list that v-select needs.
    Object.keys(groups).forEach((categoryId) => {
      const category = groups[categoryId];
      const categoryName = category.name;

      // Create a group
      flattened.push({ header: categoryName });

      // Add all the items followed by category header
      flattened.push(...category.list);
    });

    return flattened;
  }

  return {
    leadInspectorContacts,
    isPending,
    groupedLeadInspectorContacts
  };
}

export function useContactByOrganizationId(organizationId) {
  const contactsParams = computed(() => {
    return {
      query: {
        org_id: organizationId
      }
    };
  });

  const {
    items: contacts,
    isPending: contactsArePending,
    haveLoaded: contactsHaveLoaded
  } = useFind({
    model: Contact,
    params: contactsParams.value,
    qid: "contractorTsInfoList"
  });

  return {
    contacts,
    contactsArePending,
    contactsHaveLoaded
  };
}
