/**
 * @file Organizations
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the Organizations service
 */

/**
 * Find All Organizations
 * @description Returns all organizations.
 *
 *
 * @returns {object}
 */

function findAllOrganizations() {
  return {
    query: {
      $select: ["fulcrum_id", "status", "short_name"],
      status: "Active",
      $sort: {
        short_name: 1
      },
      $limit: 999
    }
  };
}
exports.findAllOrganizations = findAllOrganizations;

/**
 * Find All Contractor Organizations
 * @description Returns all contractor organizations.
 *
 *
 * @returns {object}
 */

function findAllContractorOrganizations() {
  return {
    query: {
      $select: ["fulcrum_id", "short_name", "status", "service_provided"],
      service_provided: {
        $in: ["Contractor"]
      },
      $sort: {
        short_name: 1
      }
    }
  };
}
exports.findAllContractorOrganizations = findAllContractorOrganizations;

/**
 * Find All Design Organizations
 * @description Returns all design organizations.
 *
 *
 * @returns {object}
 */

function findAllDesignOrganizations() {
  return {
    query: {
      $select: ["fulcrum_id", "short_name", "status", "service_provided"],
      service_provided: { $iLike: "%Design%" },
      $sort: {
        short_name: 1
      }
    }
  };
}
exports.findAllDesignOrganizations = findAllDesignOrganizations;
