import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { findAllFiscalYears } from "@/store/queries/fiscal-years";

const { FiscalYear } = Vue.$FeathersVuex.api;

export function useFiscalYearList() {
  const fiscalYearsParams = computed(findAllFiscalYears);

  const { items: fiscalYears, isPending } = useFind({
    model: FiscalYear,
    params: fiscalYearsParams.value,
    qid: "fiscalYears"
  });

  return {
    fiscalYears,
    isPending
  };
}
